import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spin, message, Pagination, Card, Row, Col, Button } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);

  useEffect(() => {
    AOS.init(); // Initialize AOS
    fetchBlog();
    fetchRecentBlogs();
  }, [id]);

  const fetchBlog = async () => {
    try {
      const response = await axios.get(`https://ethioalert.api.ethioalert.com/api/posts/${id}`);
      setBlog(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blog:', error);
      message.error('Failed to fetch blog');
      setLoading(false);
    }
  };

  const fetchRecentBlogs = async () => {
    try {
      const response = await axios.get('https://ethioalert.api.ethioalert.com/api/posts');
      setRecentBlogs(response.data.filter(blog => blog._id !== id));
    } catch (error) {
      console.error('Error fetching recent blogs:', error);
      message.error('Failed to fetch recent blogs');
    }
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedBlogs = recentBlogs.slice(startIndex, endIndex);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <Button 
        type="primary" 
        onClick={() => navigate(-1)} 
        className="mb-4"
        icon={<i className="fa fa-arrow-left"></i>}
      >
        Back
      </Button>
      <img
        alt={blog.title}
        src={`https://ethioalert.api.ethioalert.com/${blog.imageURL}`}
        style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
        className="mb-4"
        data-aos="fade-up" // Add AOS attribute here
      />
      <h2 className="text-4xl font-bold mb-4" data-aos="fade-up">{blog.title}</h2>
      <p className="text-xs text-gray-500 mb-4" data-aos="fade-up">{new Date(blog.date).toLocaleDateString()}</p>
      <p className="text-lg mt-4" data-aos="fade-up">{blog.content}</p>

      <div className="mt-8">
        <h3 className="text-2xl font-bold mb-4" data-aos="fade-up">Recent Blogs</h3>
        <Row gutter={16}>
          {paginatedBlogs.map((recentBlog) => (
            <Col key={recentBlog._id} xs={24} sm={12} md={8}>
              <Card
                hoverable
                cover={
                  <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                      alt={recentBlog.title}
                      src={`https://ethioalert.api.ethioalert.com/${recentBlog.imageURL}`}
                      style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                      data-aos="fade-up" // Add AOS attribute here
                    />
                  </div>
                }
              >
                <Card.Meta
                  title={recentBlog.title.length > 100 ? `${recentBlog.title.slice(0, 100)}...` : recentBlog.title}
                  description={recentBlog.content.length > 350 ? `${recentBlog.content.slice(0, 350)}...` : recentBlog.content}
                />

                <Link to={`/blog/${recentBlog._id}`} className="bg-blue-800 text-white hover:bg-blue-700 p-2 rounded-lg hover:text-gray-200 mt-2 inline-block" data-aos="fade-up">
                  Read More
                </Link>
              </Card>
            </Col>
          ))}
        </Row>

        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={recentBlogs.length}
          onChange={handlePaginationChange}
          className="mt-4"
        />
      </div>
    </div>
  );
};

export default BlogDetail;
